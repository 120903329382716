var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.loading && !_vm.items ? _c('p', [_vm._v("No items.")]) : _c('div', [_c('div', {
    staticClass: "DataTable-wrapper table-wrapper"
  }, [_vm.loading ? _c('div', {
    staticClass: "DataTable-spinner"
  }, [_c('Spinner')], 1) : _vm._e(), !_vm.noPaging && _vm.topPagination ? _c('div', {
    staticClass: "row mb-3 align-items-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('strong', [_vm._v("Page " + _vm._s(_vm.currentPage) + " of " + _vm._s(_vm.totalPages || 1) + " (" + _vm._s(_vm.totalItems) + " items total)")])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('Pagination', {
    attrs: {
      "loading": _vm.loading,
      "on-click-page": _vm.onClickPage,
      "current-page": _vm.currentPage,
      "total-pages": _vm.totalPages
    }
  })], 1)]) : _vm._e(), _c('table', {
    staticClass: "DataTable table table-borderless table-vcenter",
    class: "".concat(_vm.noHover ? '' : 'table-hover ').concat(_vm.tableClass)
  }, [_c('thead', {
    class: {
      'DataTable-hasfilters': _vm.hasFilters
    }
  }, [_c('tr', [_vm._l(_vm.columns.filter(function (c) {
    return !c.hidden;
  }), function (column, index) {
    return _c('th', {
      key: index,
      staticClass: "clickable",
      on: {
        "click": function click($event) {
          !column.noSort && _vm.onSortBy(column.code);
        }
      }
    }, [_vm._v(" " + _vm._s(column.name) + " "), !column.noSort ? _c('SortIcon', {
      attrs: {
        "is-sorted": _vm.sortKey === column.code,
        "direction": _vm.sortDirection
      }
    }) : _vm._e()], 1);
  }), _vm.actions && _vm.actions.length > 0 ? _c('th', [_vm._v("Actions")]) : _vm._e()], 2)]), _c('tbody', [_vm.hasFilters ? _c('tr', {
    staticClass: "DataTable-filters"
  }, [_vm._l(_vm.columns, function (column, index) {
    return _c('td', {
      key: index,
      staticClass: "py-2 px-3"
    }, [column.search && column.searchType === 'select2' ? _c('FormItem', {
      attrs: {
        "id": "search",
        "type": "select2",
        "config": {
          allowClear: true
        },
        "options": column.searchOptions,
        "placeholder": column.placeholder
      },
      on: {
        "input": function input(e) {
          return _vm.onSearch(e, column.searchCode || column.code, true);
        }
      }
    }) : _vm._e(), column.search && column.searchType !== 'dropdown' && column.searchType !== 'select2' ? _c('input', {
      staticClass: "form-control form-control-sm",
      attrs: {
        "type": "text",
        "placeholder": ""
      },
      domProps: {
        "value": _vm.search[column.searchCode || column.code]
      },
      on: {
        "input": function input(e) {
          return _vm.onSearch(e.target.value, column.searchCode || column.code);
        }
      }
    }) : column.search && column.searchType === 'dropdown' ? _c('select', {
      staticClass: "form-control form-control-sm",
      attrs: {
        "type": "text",
        "placeholder": ""
      },
      domProps: {
        "value": _vm.filters[column.searchCode || column.code]
      },
      on: {
        "input": function input(e) {
          return _vm.onSearch(e.target.value, column.searchCode || column.code, true);
        }
      }
    }, [_c('option'), _vm._l(column.searchOptions, function (dropdownItem) {
      return _c('option', {
        key: dropdownItem.value,
        domProps: {
          "value": dropdownItem.value,
          "selected": dropdownItem.selected
        }
      }, [_vm._v(" " + _vm._s(dropdownItem.label) + " ")]);
    })], 2) : column.checkbox ? _c('div', {
      staticClass: "custom-control custom-checkbox custom-control-lg mb-1"
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": "checkbox-all",
        "type": "checkbox",
        "name": "checkbox-all"
      },
      on: {
        "change": function change(e) {
          return _vm.onClickCheckboxAll(column, e.target.checked);
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": "checkbox-all"
      }
    })]) : _vm._e()], 1);
  }), _c('td')], 2) : _vm._e(), _vm._l(_vm.filteredItems, function (item, index) {
    return _c('tr', {
      key: index
    }, [_vm._l(_vm.columns.filter(function (c) {
      return !c.hidden;
    }), function (column, colIndex) {
      return _c('td', {
        key: colIndex
      }, [column.link && _vm.getLink(item, column.link) ? _c('span', [_c('a', {
        attrs: {
          "href": _vm.getLink(item, column.link)
        }
      }, [_vm._v(_vm._s(_vm.getItem(item, column)))])]) : column.checkbox ? _c('div', {
        staticClass: "custom-control custom-checkbox custom-control-lg mb-1"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selectedCheckboxes[_vm.getItem(item, column)],
          expression: "selectedCheckboxes[getItem(item, column)]"
        }],
        staticClass: "custom-control-input",
        attrs: {
          "id": "property-checkbox-".concat(_vm.getItem(item, column)),
          "type": "checkbox",
          "name": "property-checkbox-".concat(_vm.getItem(item, column))
        },
        domProps: {
          "checked": Array.isArray(_vm.selectedCheckboxes[_vm.getItem(item, column)]) ? _vm._i(_vm.selectedCheckboxes[_vm.getItem(item, column)], null) > -1 : _vm.selectedCheckboxes[_vm.getItem(item, column)]
        },
        on: {
          "change": [function ($event) {
            var $$a = _vm.selectedCheckboxes[_vm.getItem(item, column)],
                $$el = $event.target,
                $$c = $$el.checked ? true : false;

            if (Array.isArray($$a)) {
              var $$v = null,
                  $$i = _vm._i($$a, $$v);

              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.selectedCheckboxes, _vm.getItem(item, column), $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(_vm.selectedCheckboxes, _vm.getItem(item, column), $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(_vm.selectedCheckboxes, _vm.getItem(item, column), $$c);
            }
          }, function (e) {
            return _vm.onClickCheckbox(item, column, e.target.checked);
          }]
        }
      }), _c('label', {
        staticClass: "custom-control-label",
        attrs: {
          "for": "property-checkbox-".concat(_vm.getItem(item, column))
        }
      })]) : _c('span', [_vm._t(column.code.replace(/\./g, '-'), [_vm._v(_vm._s(_vm.getItem(item, column)))], {
        "data": item
      })], 2)]);
    }), _vm.actions && _vm.actions.length > 0 ? _c('td', [_vm._t("actions", [_c('div', {
      staticClass: "DataTable-actions"
    }, [_c('div', {
      staticClass: "DataTable-actions-btn"
    }, [_c('div', {
      staticClass: "dropdown"
    }, [_vm._m(0, true), _c('div', {
      staticClass: "dropdown-menu"
    }, [_vm._l(_vm.actions, function (action, actionIndex) {
      return [action.route && !_vm.isHidden(action, item) ? _c('router-link', {
        key: actionIndex,
        staticClass: "dropdown-item",
        class: action.class,
        attrs: {
          "data-cy": "".concat(item.id, "-").concat(action.label, "-btn").toLowerCase(),
          "to": {
            name: action.route,
            params: _vm.getActionParams(action, item)
          }
        }
      }, [action.icon ? _c('i', {
        staticClass: "fa fa-fw mr-2",
        class: "fa-".concat(action.icon)
      }) : _vm._e(), _vm._v(_vm._s(action.label))]) : _vm._e(), action.customRoute && !_vm.isHidden(action, item) ? _c('router-link', {
        key: actionIndex,
        staticClass: "dropdown-item",
        class: action.class,
        attrs: {
          "data-cy": "".concat(item.id, "-").concat(action.label, "-btn").toLowerCase(),
          "to": {
            path: _vm.handleRouterLink(action, item)
          }
        }
      }, [action.icon ? _c('i', {
        staticClass: "fa fa-fw mr-2",
        class: "fa-".concat(action.icon)
      }) : _vm._e(), _vm._v(_vm._s(action.label))]) : action.onClick && !action.onClickParams && !_vm.isHidden(action, item) ? _c('a', {
        key: "".concat(actionIndex, "-1"),
        staticClass: "dropdown-item",
        class: action.class,
        attrs: {
          "data-cy": "".concat(item.id, "-").concat(action.label, "-btn").replace(/\s+/g, '-').toLowerCase(),
          "href": "#",
          "disabled": action.loading
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return action.onClick(item);
          }
        }
      }, [action.loading ? _c('i', {
        staticClass: "fa fa-spinner-third fa-spin mr-2"
      }) : action.icon ? _c('i', {
        staticClass: "fa fa-fw mr-2",
        class: "fa-".concat(action.icon)
      }) : _vm._e(), _vm._v(_vm._s(action.label))]) : action.onClick && action.onClickParams && !_vm.isHidden(action, item) ? _c('a', {
        key: "".concat(actionIndex, "-2"),
        staticClass: "dropdown-item",
        class: action.class,
        attrs: {
          "data-cy": "".concat(item.id, "-").concat(action.label, "-btn").toLowerCase(),
          "href": "#"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return action.onClick.apply(action, [item].concat(_toConsumableArray(action.onClickParams)));
          }
        }
      }, [action.icon ? _c('i', {
        staticClass: "fa mr-2",
        class: "fa-".concat(action.icon)
      }) : _vm._e(), _vm._v(_vm._s(action.label))]) : action.href && !_vm.isHidden(action, item) ? _c('a', {
        key: "".concat(actionIndex, "-3"),
        class: action.class,
        attrs: {
          "href": action.href
        }
      }, [_vm._v(_vm._s(action.label))]) : _vm._e()];
    })], 2)])])])], {
      "data": item
    })], 2) : _vm._e()], 2);
  })], 2)])]), !_vm.noPaging ? _c('div', {
    staticClass: "row mb-4 align-items-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('strong', [_vm._v("Page " + _vm._s(_vm.currentPage) + " of " + _vm._s(_vm.totalPages || 1) + " (" + _vm._s(_vm.totalItems) + " items total)")])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('Pagination', {
    attrs: {
      "loading": _vm.loading,
      "on-click-page": _vm.onClickPage,
      "current-page": _vm.currentPage,
      "total-pages": _vm.totalPages
    }
  })], 1)]) : _vm._e()])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn text-secondary btn-link",
    attrs: {
      "data-toggle": "dropdown"
    }
  }, [_c('i', {
    staticClass: "fa fa-ellipsis"
  })]);
}]

export { render, staticRenderFns }