<template>
  <div>
    <p v-if="!loading && !items">No items.</p>
    <div v-else>
      <div class="DataTable-wrapper table-wrapper">
        <div v-if="loading" class="DataTable-spinner">
          <Spinner />
        </div>
        <div v-if="!noPaging && topPagination" class="row mb-3 align-items-center">
          <div class="col-md-6">
            <strong>Page {{ currentPage }} of {{ totalPages || 1 }} ({{ totalItems }} items total)</strong>
          </div>
          <div class="col-md-6">
            <Pagination :loading="loading" :on-click-page="onClickPage" :current-page="currentPage" :total-pages="totalPages"></Pagination>
          </div>
        </div>
        <table class="DataTable table table-borderless table-vcenter" :class="`${noHover ? '' : 'table-hover '}${tableClass}`">
          <thead :class="{ 'DataTable-hasfilters': hasFilters }">
            <tr>
              <th
                v-for="(column, index) in columns.filter(c => !c.hidden)"
                :key="index"
                class="clickable"
                @click="!column.noSort && onSortBy(column.code)"
              >
                {{ column.name }}
                <SortIcon v-if="!column.noSort" :is-sorted="sortKey === column.code" :direction="sortDirection"></SortIcon>
              </th>
              <th v-if="actions && actions.length > 0">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="hasFilters" class="DataTable-filters">
              <td v-for="(column, index) in columns" :key="index" class="py-2 px-3">
                <FormItem
                  v-if="column.search && column.searchType === 'select2'"
                  id="search"
                  type="select2"
                  :config="{ allowClear: true }"
                  :options="column.searchOptions"
                  :placeholder="column.placeholder"
                  @input="e => onSearch(e, column.searchCode || column.code, true)"
                />
                <input
                  v-if="column.search && column.searchType !== 'dropdown' && column.searchType !== 'select2'"
                  class="form-control form-control-sm"
                  type="text"
                  placeholder=""
                  :value="search[column.searchCode || column.code]"
                  @input="e => onSearch(e.target.value, column.searchCode || column.code)"
                />
                <select
                  v-else-if="column.search && column.searchType === 'dropdown'"
                  class="form-control form-control-sm"
                  type="text"
                  placeholder=""
                  :value="filters[column.searchCode || column.code]"
                  @input="e => onSearch(e.target.value, column.searchCode || column.code, true)"
                >
                  <option></option>
                  <option
                    v-for="dropdownItem in column.searchOptions"
                    :key="dropdownItem.value"
                    :value="dropdownItem.value"
                    :selected="dropdownItem.selected"
                  >
                    {{ dropdownItem.label }}
                  </option>
                </select>
                <div v-else-if="column.checkbox" class="custom-control custom-checkbox custom-control-lg mb-1">
                  <input
                    id="checkbox-all"
                    type="checkbox"
                    class="custom-control-input"
                    name="checkbox-all"
                    @change="e => onClickCheckboxAll(column, e.target.checked)"
                  />
                  <label class="custom-control-label" for="checkbox-all"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in filteredItems" :key="index">
              <td v-for="(column, colIndex) in columns.filter(c => !c.hidden)" :key="colIndex">
                <span v-if="column.link && getLink(item, column.link)"
                  ><a :href="getLink(item, column.link)">{{ getItem(item, column) }}</a>
                </span>
                <div v-else-if="column.checkbox" class="custom-control custom-checkbox custom-control-lg mb-1">
                  <input
                    :id="`property-checkbox-${getItem(item, column)}`"
                    v-model="selectedCheckboxes[getItem(item, column)]"
                    type="checkbox"
                    class="custom-control-input"
                    :name="`property-checkbox-${getItem(item, column)}`"
                    @change="e => onClickCheckbox(item, column, e.target.checked)"
                  />
                  <label class="custom-control-label" :for="`property-checkbox-${getItem(item, column)}`"></label>
                </div>
                <span v-else
                  ><slot :name="column.code.replace(/\./g, '-')" :data="item">{{ getItem(item, column) }}</slot></span
                >
              </td>
              <td v-if="actions && actions.length > 0">
                <slot name="actions" :data="item">
                  <div class="DataTable-actions">
                    <div class="DataTable-actions-btn">
                      <div class="dropdown">
                        <button class="btn text-secondary btn-link" data-toggle="dropdown">
                          <i class="fa fa-ellipsis"></i>
                        </button>
                        <div class="dropdown-menu">
                          <template v-for="(action, actionIndex) in actions">
                            <router-link
                              v-if="action.route && !isHidden(action, item)"
                              :key="actionIndex"
                              :data-cy="`${item.id}-${action.label}-btn`.toLowerCase()"
                              :to="{ name: action.route, params: getActionParams(action, item) }"
                              class="dropdown-item"
                              :class="action.class"
                              ><i v-if="action.icon" class="fa fa-fw mr-2" :class="`fa-${action.icon}`"></i>{{ action.label }}</router-link
                            >
                            <router-link
                              v-if="action.customRoute && !isHidden(action, item)"
                              :key="actionIndex"
                              :data-cy="`${item.id}-${action.label}-btn`.toLowerCase()"
                              :to="{ path: handleRouterLink(action, item) }"
                              class="dropdown-item"
                              :class="action.class"
                              ><i v-if="action.icon" class="fa fa-fw mr-2" :class="`fa-${action.icon}`"></i>{{ action.label }}</router-link
                            ><a
                              v-else-if="action.onClick && !action.onClickParams && !isHidden(action, item)"
                              :key="`${actionIndex}-1`"
                              :data-cy="`${item.id}-${action.label}-btn`.replace(/\s+/g, '-').toLowerCase()"
                              href="#"
                              class="dropdown-item"
                              :class="action.class"
                              :disabled="action.loading"
                              @click.prevent="action.onClick(item)"
                              ><i v-if="action.loading" class="fa fa-spinner-third fa-spin mr-2"></i
                              ><i v-else-if="action.icon" class="fa fa-fw mr-2" :class="`fa-${action.icon}`"></i>{{ action.label }}</a
                            ><a
                              v-else-if="action.onClick && action.onClickParams && !isHidden(action, item)"
                              :key="`${actionIndex}-2`"
                              :data-cy="`${item.id}-${action.label}-btn`.toLowerCase()"
                              href="#"
                              class="dropdown-item"
                              :class="action.class"
                              @click.prevent="action.onClick(item, ...action.onClickParams)"
                              ><i v-if="action.icon" class="fa mr-2" :class="`fa-${action.icon}`"></i>{{ action.label }}</a
                            ><a
                              v-else-if="action.href && !isHidden(action, item)"
                              :key="`${actionIndex}-3`"
                              :href="action.href"
                              :class="action.class"
                              >{{ action.label }}</a
                            >
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!noPaging" class="row mb-4 align-items-center">
        <div class="col-md-6">
          <strong>Page {{ currentPage }} of {{ totalPages || 1 }} ({{ totalItems }} items total)</strong>
        </div>
        <div class="col-md-6">
          <Pagination :loading="loading" :on-click-page="onClickPage" :current-page="currentPage" :total-pages="totalPages"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { throttle, get, debounce } from 'lodash';

import Pagination from './Pagination';
import SortIcon from './SortIcon';
import FormItem from '@/components/FormItem';
import Spinner from '@/components/SpinnerLogo';

export default {
  name: 'DataTable',
  components: {
    Pagination,
    SortIcon,
    FormItem,
    Spinner
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    fetchItems: {
      type: Function,
      default: () => null
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    otherParams: {
      type: Object,
      default: () => ({})
    },
    actions: {
      type: Array,
      default: () => []
    },
    shallowSearch: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 1000
    },
    defaultSortKey: {
      type: String,
      default: 'name'
    },
    defaultSortDirection: {
      type: Number,
      default: 1
    },
    noPaging: {
      type: Boolean,
      default: false
    },
    noHover: {
      type: Boolean,
      default: false
    },
    tableClass: {
      type: String,
      default: ''
    },
    pagination: {
      type: Object,
      default: null
    },
    topPagination: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      targetPage: 1,
      sortKey: this.defaultSortKey,
      sortDirection: this.defaultSortDirection,
      search: {},
      selectedCheckboxes: {},
      resetPagination: false
    };
  },
  computed: {
    hasFilters() {
      // Determines if this datatables needs a filters row
      return this.columns.some(c => !c.hidden && (c.search || c.checkbox));
    },
    currentPage() {
      return this.pagination ? this.pagination.page : this.targetPage;
    },
    totalPages() {
      if (this.pagination) return this.pagination.pages;

      return Math.ceil(this.total / this.pageSize);
    },
    totalItems() {
      return this.pagination ? this.pagination.total : this.total;
    },
    filteredItems() {
      let items = this.items;

      if (this.shallowSearch && Object.keys(this.search).length > 0) {
        items = this.items.filter(item =>
          Object.keys(this.search).every(s => {
            const regex = RegExp(this.search[s], 'i');
            return regex.test(get(item, s));
          })
        );
      }

      if (this.shallowSearch && this.sortKey) {
        items.sort((a, b) => String(get(a, this.sortKey)).localeCompare(String(get(b, this.sortKey)), 'en', { numeric: true }) * this.sortDirection);
      }

      if (this.shallowSearch) {
        return items.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
      }

      return items;
    },
    refreshParams() {
      // Filters that are not null
      const filters = Object.keys(this.filters).reduce((acc, curr) => {
        if (this.filters[curr]) {
          acc[curr] = this.filters[curr];
        }
        return acc;
      }, {});

      const params = {
        $limit: this.shallowSearch ? 100000 : this.pageSize,
        $skip: this.shallowSearch ? 0 : this.pageSize * (this.currentPage - 1),
        $sort: `${this.sortKey}:${this.sortDirection}`,
        ...filters,
        ...this.otherParams
      };

      if (!this.shallowSearch) {
        const searchQueryString = Object.keys(this.search)
          .filter(key => this.search[key])
          .map(key => `${key}:${this.search[key]}`)
          .join('|');

        if (searchQueryString) params.$search = searchQueryString;
      }

      if (this.pagination && !this.resetPagination) {
        params.$firstToken = this.pagination.firstToken;
        params.$lastToken = this.pagination.lastToken;
        params.$lastPage = this.currentPage;
        params.$page = this.targetPage;
      }

      return params;
    }
  },
  watch: {
    filters: {
      handler: function () {
        if (this.shallowSearch) this.refresh();
      },
      deep: true
    },
    otherParams: {
      handler: function () {
        this.refresh();
      },
      deep: true
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    getItem(item, column) {
      return get(item, column.code);
    },
    getActionParams(action, item) {
      const params = {};
      Object.keys(action.params).forEach(param => {
        const key = action.params[param];
        params[param] = key.charAt(0) === '$' ? get(item, key.slice(1)) : get(item, key);
      });
      return params;
    },
    async onClickPage(page) {
      this.targetPage = page;

      if (!this.shallowSearch) {
        await this.refresh();
      }
    },
    onSortBy(key) {
      this.sortKey = key;
      this.sortDirection = -this.sortDirection;

      if (!this.shallowSearch) {
        this.refresh();
      }
    },
    onSearch: debounce(function (value, code, exact = false) {
      this.$set(exact ? this.filters : this.search, code, value || undefined);

      this.targetPage = 1;
      this.resetPagination = true;

      if (!this.shallowSearch) {
        this.refresh();
      }
    }, 500),
    isHidden(action, item) {
      if (Array.isArray(action.hide)) {
        return action.hide.some(str => {
          const [key, value] = str.split(' === ');
          if (value === 'null' && !get(item, key)) return true;
          return get(item, key) === value;
        });
      }

      if (Array.isArray(action.show)) {
        return action.show.every(str => {
          if (str.includes('&&')) {
            let [first, second] = str.split(' && ');
            let [firstKey, firstValue] = first.split(' === ');
            let [secondKey, secondValue] = second.split(' === ');

            const array = [firstValue, secondValue];
            array.forEach((value, index) => {
              if (value === 'true') array[index] = true;
              if (value === 'false') array[index] = false;
            });

            if (get(item, firstKey, false) === array[0] && get(item, secondKey, false) === array[1]) return false;
            return true;
          }

          let [key, value] = str.split(' === ');
          if (value === 'null' && !get(item, key, false)) return true;
          if (value === 'false') value = false;
          if (value === 'true') value = true;
          return get(item, key, false) !== value;
        });
      }

      if (action.hide && get(item, action.hide)) {
        return true;
      } else if (action.show && get(item, action.show) === false) {
        return true;
      }

      return false;
    },
    handleRouterLink(action, item) {
      const firstIndex = action.customRoute.indexOf('{');
      const secondIndex = action.customRoute.indexOf('}');
      const key = action.customRoute.substring(firstIndex + 1, secondIndex);
      const toBeReplaced = action.customRoute.substring(firstIndex - 1, secondIndex + 1);
      return action.customRoute.replace(toBeReplaced, get(item, key));
    },
    getLink(item, link) {
      const regex = new RegExp('{{(.*?)}}');
      if (!regex) return null;

      const capture = regex.exec(link);
      const linkData = get(item, capture[1]);
      return link.replace(capture[0], linkData);
    },
    onClickCheckbox(item, column, isChecked) {
      this.$emit('onCheckbox', { item, isChecked });
    },
    onClickCheckboxAll(column, isChecked) {
      this.filteredItems.forEach(item => {
        this.$set(this.selectedCheckboxes, this.getItem(item, column), isChecked);
        this.$emit('onCheckbox', { item, isChecked });
      });
    },
    refresh: throttle(async function db() {
      await this.fetchItems({
        data: {
          params: this.refreshParams
        }
      });

      this.resetPagination = false;
    }, 500)
  }
};
</script>
<style lang="scss">
@import '~@/assets/_scss/custom/variables';

.DataTable {
  &-filters {
    background-color: $gray-lines;
    border-bottom: 0 !important;
    height: 60px;

    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &-actions {
    display: flex;
    white-space: nowrap;
  }
  &-wrapper {
    position: relative;
  }
  &-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 90%);
    z-index: 50;
    padding-top: 50px;
  }

  tr {
    border-bottom: 2px solid $gray-lines;
  }

  thead.DataTable-hasfilters {
    th,
    tr {
      border-bottom: 0;
    }
  }

  &.table-hover tbody tr:hover {
    background-color: $gray-lines;
  }
}
.clickable {
  cursor: pointer;
}

.page-item {
  cursor: pointer;
}
</style>
