<template>
  <span class="ml-2"
    ><div v-if="isSorted" class="fa" :class="{ 'fa-sort-up': direction &gt; 0, 'fa-sort-down': direction &lt; 0 }"></div>
    <div v-else class="fa fa-sort"></div
  ></span>
</template>
<script>
export default {
  name: 'SortIcon',
  props: {
    direction: {
      type: Number,
      default: 1
    },
    isSorted: {
      type: Boolean
    }
  }
};
</script>
